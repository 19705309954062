import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { FirstPartyDataServiceService } from 'src/app/services/first-party-data-service.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { CountryCodes } from '../../../consts/countryCode';
import { DaysRangeSelectorComponent } from '../first-party-data/days-range-selector/days-range-selector.component';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { RumService } from 'src/app/services/rum.service';

@Component({
  selector: 'app-first-party-internal-detail',
  templateUrl: './first-party-internal-detail.component.html',
  styleUrls: ['./first-party-internal-detail.component.scss'],
})
export class FirstPartyInternalDetailComponent implements OnInit {
  @ViewChild('menuContacts') menuContacts;
  @Output() public deviceEvent = new EventEmitter<string>();
  device: string = 'desktop';
  queryLevel: string = 'page';
  queryPeriod: string = 'daily';
  loading: boolean = true;
  userId = localStorage.getItem('userId');

  dataList = [];
  apiResponseMessage: string = '';

  selectedDimension: string = 'all';
  selectedDimGroup: string = 'all';

  siteId;
  detailUrl;
  metricsData;
  attributionData = {};
  overTimeBarchartData = {};
  overTimeP75Data = {};
  lcpAttributionDatasource = new MatTableDataSource([]);
  metrics = ['lcp', 'inp', 'cls', 'ttfb', 'fcp'];
  displayedColumns = ['lcpElement', 'resourceURL', 'p75thValue', 'pvEvents'];

  params;
  currentComputedData = {};
  deviceTypes = ConstantService.defaultDevices;
  dimensionGroups = ConstantService.defaultDimensionGroup;
  siteTitle;
  detailUrlDecoded;
  showDebugLcp = false;
  showDebugCLS = false;
  showDebugINP = false;

  allMetricOverTimeData = [];
  sites;
  site: any;
  SiteTitle: any;
  sharedMode = false;
  monitoringLocations = [];
  selectedCountry = 'Global';
  startDate: any;
  endDate: any;
  numberOfDataPoints = 30;
  daysRangeSelected = '7';
  metricsLoading;
  partitionedDataOverTime: any = {};
  allDaysRange = [];
  selectedDatesRange;
  loadMetric = false;

  constructor(
    public webAnalyzeService: WebsiteAnalyzeService,
    private router: Router,
    private fpdService: FirstPartyDataServiceService,
    private rumService: RumService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    const urlD = this.router.url.split('/');
    this.siteId = urlD[3];
    this.detailUrl = urlD[urlD.length - 1].split('=').pop();
    this.detailUrlDecoded = decodeURIComponent(
      decodeURIComponent(this.detailUrl)
    );
    this.detailUrl = this.detailUrlDecoded;
  }

  async ngOnInit() {
    this.device = localStorage.getItem(ConstantService.loadingUrl.device);
    const userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId
    );
    this.selectedDimension =
      localStorage.getItem(ConstantService.loadingUrl.fpdDimension) || 'all';
    this.selectedDimGroup =
      localStorage.getItem(ConstantService.loadingUrl.fpdDimGroup) || 'all';
    this.queryPeriod =
      localStorage.getItem(ConstantService.loadingUrl.fpdQueryPeriod) ||
      'daily';
    this.sharedMode =
      localStorage.getItem(ConstantService.localStorageKeys.sharedMode) ===
      'on';
    this.selectedCountry =
      localStorage.getItem(
        ConstantService.localStorageKeys.selectedRUMCountry
      ) || 'Global';
    this.fetchMetricsData();
  }

  onCountrySelect(country) {
    this.selectedCountry = country;
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      country
    );
    this.updateTableData();
  }

  setDevice(selectedDevice: string) {
    this.device = selectedDevice;
    localStorage.setItem(ConstantService.loadingUrl.device, this.device);

    this.selectedDimGroup = 'all';
    this.selectedDimension = 'all';

    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimension,
      this.selectedDimension
    );
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      this.selectedDimGroup
    );
    this.updateTableData(false);
  }

  executeSelectionChange() {
    if (this.daysRangeSelected == 'custom') return;
    this.startDate = null;
    this.endDate = null;
    this.loadMetricOverTimeData();
  }

  updateOnDataPointsChange() {
    this.updateTableData(false);
  }

  updateTableData(isAfterLoading = true) {
    this.metricsData = this.rumService.getRUMInternalPageDetailAll(
      this.dataList[this.dataList.length - 1] || {},
      this.selectedDimGroup,
      this.selectedDimension,
      this.device,
      this.selectedCountry,
      this.metrics
    );


    // this.lcpAttributionDatasource.data =
    //   this.dataList?.[0]?.attributionData?.[this.device]?.lcp;
    const processedOverTime = this.rumService.getRUMInternalPageDetailOvertime(
      this.dataList,
      this.selectedDimGroup,
      this.selectedDimension,
      this.device,
      this.selectedCountry,
      this.queryPeriod
    );

    this.overTimeP75Data = processedOverTime.p75OverTime;
    this.overTimeBarchartData = processedOverTime.barchartData;
    if (this.queryPeriod === 'daily') {
      const partitioned = this.partitionData(this.dataList);
      this.allDaysRange = partitioned?.dateGroups || [];
      for (const daysRange of this.allDaysRange) {
        const partiData = partitioned?.[daysRange] || {};
        this.partitionedDataOverTime[daysRange] =
          this.rumService.getRUMInternalPageDetailOvertime(
            partiData,
            this.selectedDimGroup,
            this.selectedDimension,
            this.device,
            this.selectedCountry,
            this.queryPeriod
          );
      }
      const daysRange = isAfterLoading
        ? this.allDaysRange[this.allDaysRange.length - 1]
        : this.selectedDatesRange;
      if (isAfterLoading) {
        this.selectedDatesRange = daysRange;
      }
      this.overTimeP75Data = {
        ...this.partitionedDataOverTime?.[daysRange]?.p75OverTime,
      };
      this.overTimeBarchartData = {
        ...this.partitionedDataOverTime?.[daysRange]?.barchartData,
      };
    } else {
      const processedOverTime =
        this.rumService.getRUMInternalPageDetailOvertime(
          this.dataList,
          this.selectedDimGroup,
          this.selectedDimension,
          this.device,
          this.selectedCountry,
          this.queryPeriod
        );

      this.overTimeP75Data = processedOverTime.p75OverTime;
      this.overTimeBarchartData = processedOverTime.barchartData;
    }
  }

  setSelectedDatesRange(day) {
    this.selectedDatesRange = day;
    this.updateTableData(false);
  }

  setQueryPeriod(event: Event) {
    const target = event.target as HTMLInputElement;
    const QueryPeriod = target.value;

    this.deviceEvent.emit(QueryPeriod);
    this.queryPeriod = QueryPeriod;
    localStorage.setItem(
      ConstantService.loadingUrl.fpdQueryPeriod,
      this.queryPeriod
    );

    this.fetchMetricsData();
  }

  setDimension(dimension, dimensionGroup) {
    this.selectedDimension = dimension;
    this.selectedDimGroup = dimensionGroup;
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      dimensionGroup
    );
    localStorage.setItem(ConstantService.loadingUrl.fpdDimension, dimension);
    this.updateTableData(false);
  }

  async fetchMetricsData() {
    this.siteTitle = decodeURIComponent(
      extractDomainName(decodeURIComponent(this.detailUrl))
    );
    this.loading = true;

    try {
      const { data: result, locations } = await this.webAnalyzeService
        .getRumInternalPageDetail(
          this.siteId,
          this.queryPeriod,
          this.detailUrl,
          this.daysRangeSelected
        )
        .toPromise();
   
      this.monitoringLocations = locations;
      if (!result.length) {
        this.apiResponseMessage = 'No data for the selected options';
        this.loading = false;
        this.metricsData = null;
        return;
      }
      const { title } = result[0];
      this.siteTitle = title;

      this.dataList = result;
      this.attributionData = result?.[0]?.attributionData;
      this.lcpAttributionDatasource.data =
        this.attributionData?.[this.device]?.lcp;
      this.updateTableData();
      this.loading = false;
      console.log(this.attributionData)
      return;
    } catch (err) {
      console.error(err);
      this.dataList = [];
      this.loading = false;
    }
  }

  async loadMetricOverTimeData() {
    try {
      this.metricsLoading = true;

      const { data: result } = await this.webAnalyzeService
        .getRumInternalPageDetail(this.siteId, this.queryPeriod, this.detailUrl, this.daysRangeSelected)
        .toPromise();
      this.dataList = result;
      this.updateTableData();
      this.metricsLoading = false;
    } catch (error) {
      console.error(error);
    }
  }

  clearCurrentFilters() {
    this.selectedCountry = 'Global';
    this.selectedDimGroup = 'all';
    this.selectedDimension = 'all';
    localStorage.setItem(
      ConstantService.localStorageKeys.selectedRUMCountry,
      this.selectedCountry
    );
    localStorage.setItem(
      ConstantService.loadingUrl.fpdDimGroup,
      this.selectedDimGroup
    );
    localStorage.setItem(ConstantService.loadingUrl.fpdDimension, 'all');
    this.updateTableData();
  }

  extractAttributionDataFromString(metricData: any[]) {
    const allAttributions = {};
    ['TTFB', 'FCP', 'LCP', 'CLS', 'INP'].forEach((attr: string) => {
      const aString: string = (
        metricData.find((m) => m.eventType === attr) || {}
      ).all_attributions;
      allAttributions[attr.toLowerCase()] = JSON.parse(
        `[${aString.split('C_O_N_C_A_T').join(',')}]`
      );
    });
    return allAttributions;
  }

  processAttributionData(metricData: any[]) {
    const allAt = this.extractAttributionDataFromString(metricData);
    Object.keys(allAt).forEach((attr) => {
      switch (attr) {
        case 'ttfb':
          let waitingTime = 0,
            dnsTime = 0,
            connectionTime = 0,
            requestTime = 0;
          const allTtfb = allAt[attr];
          allTtfb.forEach((d) => {
            waitingTime += d.waitingTime;
            dnsTime += d.dnsTime;
            connectionTime += d.connectionTime;
            requestTime += d.requestTime;
          });

          this.attributionData[attr] = {
            waitingTime: (waitingTime / (allTtfb.length || 1)).toFixed(1),
            dnsTime: (dnsTime / (allTtfb.length || 1)).toFixed(1),
            connectionTime: (connectionTime / (allTtfb.length || 1)).toFixed(1),
            requestTime: (requestTime / (allTtfb.length || 1)).toFixed(1),
            total: (
              (waitingTime + dnsTime + connectionTime + requestTime) /
              (allTtfb.length || 1)
            ).toFixed(1),
          };
          break;
        case 'fcp':
          let timeToFirstByte = 0,
            firstByteToFcp = 0;
          const allFcp = allAt[attr];

          allFcp.forEach((d) => {
            timeToFirstByte += d.timeToFirstByte;
            firstByteToFcp += d.firstByteToFCP;
          });
          this.attributionData[attr] = {
            timeToFirstByte: (timeToFirstByte / (allFcp.length || 1)).toFixed(
              1
            ),
            firstByteToFcp: (firstByteToFcp / (allFcp.length || 1)).toFixed(1),
          };
          break;
        case 'lcp':
          const allLcp = allAt[attr];
          allLcp.sort((a, b) => {
            const aAllDelay =
              (a.timeToFirstByte || 0) +
              (a.resourceLoadDelay || 0) +
              (a.resouceLoadTime || 0) +
              (a.elementRenderDelay || 0);

            const bAllDelay =
              (b.timeToFirstByte || 0) +
              (b.resourceLoadDelay || 0) +
              (b.resouceLoadTime || 0) +
              (b.elementRenderDelay || 0);

            return aAllDelay > bAllDelay ? -1 : 1;
          });
          this.attributionData[attr] = allLcp[0];
          break;
        case 'cls':
          const allCls = allAt[attr];
          allCls.sort((a, b) => {
            return a.largestShiftValue > b.largestShiftValue ? -1 : 1;
          });
          this.attributionData[attr] = allCls[0];
          break;
      }
    });
  }

  onMatSortChange(event) {
    const { active, direction } = event;
    let data = this.lcpAttributionDatasource.data;
    const isAsc = direction === 'asc';
    switch (active) {
      case 'pvEvents':
        data.sort((a, b) => compare(a.count, b.count, isAsc));
        break;
    }
    this.lcpAttributionDatasource.data = data;
  }

  getPercentage(numinator, denominator) {
    return `${((numinator / (denominator || 1)) * 100).toFixed(1)}%`;
  }

  formatToFixed(number, place = 1) {
    return number?.toFixed(place);
  }

  p75thData(arr) {
    return this.fpdService.getPercentileValue(arr, 0.75);
  }

  getPages() {
    return {
      overview: '/home/rum/' + this.siteId + '/overview',
      internal: '/home/rum/' + this.siteId + '/internalpages',
    };
  }
  copyUrlToClipboard(url: any): void {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        this.snackBar.open('URL copied!', 'Close', {
          duration: 2000,
        });
      })
      .catch((err) => {
        this.snackBar.open('Error copying URL!', 'Close', {
          duration: 2000,
        });
      });
  }
  estAlloue(code): boolean {
    if (code == 'NotAllowed' || code == undefined || code == 'Global')
      return false;
    return true;
  }
  getCountryCode(countryName: string): string | undefined {
    return CountryCodes[countryName];
  }

  openDaysSelectorDialog() {
    const dialogRef = this.dialog.open(DaysRangeSelectorComponent, {
      height: '300px',
      width: '600px',
    });
    dialogRef.componentInstance.selectionChange.subscribe((val) => {
      this.startDate = val.startDate;
      this.endDate = val.endDate;
      this.daysRangeSelected = `${this.startDate}_${this.endDate}`;
      this.numberOfDataPoints = val.numberOfDataPoints;
      this.loadMetricOverTimeData();
    });
    dialogRef
      .afterClosed()
      .subscribe(() =>
        dialogRef.componentInstance.selectionChange.unsubscribe()
      );
  }

  partitionData(data) {
    const dates = data.map((d) => d.date);
    if (!dates.length) return;
    if (!dates.length) return;

    const rounds = Math.floor(dates.length / 15);
    const reminder = dates.length % 15;
    const dateGroups = [];
    const dataByRange = {};

    if (rounds > 0) {
      for (let i = 0; i < rounds; i++) {
        const currentRange = [15 * i, 15 * (i + 1)];
        const dateGroup = `${dates[currentRange[0]]} to ${
          dates[currentRange[1] - 1]
        }`;
        dateGroups.push(dateGroup);

        dataByRange[dateGroup] = data.slice(currentRange[0], currentRange[1]);

        if (reminder > 0) {
          const startIndex = rounds * 15;
          const endIndex = dates.length;
          const dateGroup = `${dates[startIndex]} to ${dates[endIndex - 1]}`;
          dateGroups.push(dateGroup);

          dataByRange[dateGroup] = data.slice(startIndex, endIndex);
        }
      }
    }
    if (!rounds && !!reminder) {
      const dateGroup = `${dates[0]} to ${dates[reminder - 1]}`;
      dateGroups.push(dateGroup);

      dataByRange[dateGroup] = data.slice(0, reminder);
    }
    return {
      dateGroups,
      ...dataByRange,
    };
  }
  closeMe(menuTrigger: MatMenuTrigger) {
    menuTrigger.closeMenu();
  }
}

function extractDomainName(url) {
  var hostname;
  var regex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?]+)/im;
  var match = url.match(regex);
  if (match != null && match.length > 1) {
    hostname = match[1];
    var domainRegex = /^([^\.]+\.[^\.]+)(\.[^\.]+)?$/;
    var domainMatch = hostname.match(domainRegex);
    if (domainMatch != null && domainMatch.length > 1) {
      hostname = domainMatch[1];
    }
  }
  return hostname;
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
