

<div class="d-flex flex-column justify-content-center align-items-center">
  <div class="checklist-container p-4">
    <!-- Title -->
    @if(!allRequiredDone){
    <h2 class="onboarding-title text-center">{{ mainTitle }}</h2>
    }@else if(checklistId === "listwebsite"){
      <h2 class="onboarding-title text-center">Onboarding done for CRUX</h2>
    }@else{
      <h2 class="onboarding-title text-center">Onboarding done for RUM</h2>
    }
    <!-- Example: small progress info -->
    <div class="progress-wrap" *ngIf="totalSteps > 0">

        <div class="progress-info">
          {{ completedSteps }} / {{ totalSteps }} completed
        </div>
      <div class="progress-bar-background">
        <div
          class="progress-bar-foreground"
          [style.width.%]="progressPercentage"
        ></div>
      </div>
    </div>

    <!-- Steps list -->
    <div class="steps-list">
      <div
        class="step-item my-4"
        *ngFor="let step of steps; let i = index"
      >
        <input
          type="checkbox"
          [checked]="step.done"
          (change)="step.done = !step.done"
          id="step-{{ i }}"
        />
        <label for="step-{{ i }}" [innerHTML]="step.label">
        </label>
      </div>
    </div>
  </div>

  <!--
    Small notification if we are receiving data
    (with a confetti or celebration icon)
  -->
  <div
    class="alert alert-info d-flex align-items-center justify-content-center mt-3"
    *ngIf="areWeReceivingData"
  >
    <mat-icon  style="color: #ff9800;">celebration</mat-icon>
    <span class="ms-2">We have started receiving data from your site, please allow at least one day for metrics to become visible.</span>

  </div>
</div>

