import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
  OnInit
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { Router, Event } from '@angular/router';
import { UserStateService } from 'src/app/services/common/user/user-state.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav;
  public isShowSidebar: boolean;
  public mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  public trialMessage: string | null = null;
  public isTrialMessageVisible: boolean = true;
  public userId: string;

  // Manages the router subscription so we can unsubscribe later
  private routerSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private router: Router,
    private pageSpeedInsigthService: PageSpeedInsigthService,
    public userService: UserStateService,
  ) {
    // (1) Define the listener before registering it
    this.mobileQueryListener = () => {
      // Close or open the sidebar based on screen size
      this.isShowSidebar = !this.mobileQuery.matches;
      this.changeDetectorRef.detectChanges();
    };

    // (2) Create the media query
    this.mobileQuery = this.media.matchMedia('(max-width: 746px)');

    // (3) Use addEventListener instead of addListener (deprecated)
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);

    // (4) Determine the initial state of the sidebar
    this.isShowSidebar = !this.mobileQuery.matches;

    // (5) Always listen to router events and close sidebar in mobile mode
    this.routerSubscription = this.router.events.subscribe(() => {
      if (this.mobileQuery.matches) {
        this.isShowSidebar = false;
      }
    });

    // (6) Inform the PageSpeedInsigthService if we are in mobile mode
    this.pageSpeedInsigthService.mobileShow(this.mobileQuery.matches);

    // (7) Check if the trial message was previously closed in this session
    const trialMessageClosed = sessionStorage.getItem('trialMessageClosed');
    if (trialMessageClosed === 'true') {
      this.isTrialMessageVisible = false;
    }
  }

  // (8) Renamed from get 0.9() to a valid identifier
  get isHomeOrRumPage(): boolean {
    return (
      this.router.url.startsWith('/home/page') ||
      this.router.url.startsWith('/home/rum/')
    );
  }

  async ngOnInit(): Promise<void> {
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
    const userData = this.userService.user();

    if (userData.user.isTrialActive !== null && userData.user.trialEndDate) {
      const daysLeft = this.calculateDaysLeft(userData.user.trialEndDate);
      if (daysLeft > 0 && daysLeft <= 7) {
        this.trialMessage = `Your trial ends in ${daysLeft} day${
          daysLeft > 1 ? 's' : ''
        }`;
      } else if (daysLeft <= 0 && userData.user.subStatus === 'inactive') {
        this.trialMessage = `Your trial period has ended. Subscribe now to regain access to premium features!`;
      }
    }
  }

  /**
   * Calculates the number of days left until a given date.
   * @param endDateString - The end date as a string.
   * @returns Number of days left (negative if the date has passed).
   */
  private calculateDaysLeft(endDateString: string): number {
    const now = new Date();
    const endDate = new Date(endDateString);
    const diffTime = endDate.getTime() - now.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  public navigateToSubscription(): void {
    this.router.navigate(['/buyPlan']);
  }

  public closeTrialMessage(): void {
    this.isTrialMessageVisible = false;
    sessionStorage.setItem('trialMessageClosed', 'true');
  }

  public ngOnDestroy(): void {
    // (9) Use removeEventListener instead of removeListener
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);

    // (10) Unsubscribe from router events to avoid memory leaks
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.sidenav) {
      this.sidenav.close();
    }
  }
}
