import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';

import { HeaderModule } from './header/header.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { SettingsMenuComponent } from './ui-elements/settings-menu/settings-menu.component';
import { DateMenuComponent } from './ui-elements/date-menu/date-menu.component';
import { LayoutComponent } from './layout/layout.component';
import { CanAddMoreCompetitorsDirective } from './directives/can-add-more-competitors.directive';
import { CanAddMoreInternalsPagesDirective } from './directives/can-add-more-internals-pages.directive';
import { LayoutComponentAll } from './layout-all/layoutAll.component';
import { FreeMsgComponent } from './msg/Free-msg/Free-msg.component';
import { MaterialModule } from 'src/app/shared/module/material/material.module';
import { OfferBannerComponent } from './offer-banner/offer-banner.component';
import { FeedbackWidgetComponent } from './feedback/feedback-widget/feedback-widget.component';
import { CheckListComponent } from './check-list/check-list.component'
import { OopsNoDataComponent } from './oops-no-data/oops-no-data.component'
@NgModule({
  declarations: [
    SidebarComponent,
    FooterComponent,
    SettingsMenuComponent,
    DateMenuComponent,
    LayoutComponent,
    LayoutComponentAll,
    CanAddMoreCompetitorsDirective,
    CanAddMoreInternalsPagesDirective,
    FreeMsgComponent,
    OfferBannerComponent,
    FeedbackWidgetComponent,
    CheckListComponent,
    OopsNoDataComponent
  ],
  imports: [
    HeaderModule,
    MatListModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    CommonModule,
    MatMenuModule,
    MatSelectModule,
    FormsModule,
    MatSidenavModule,
    MaterialModule,
    ReactiveFormsModule,

  ],
  exports: [
    HeaderModule,
    OfferBannerComponent,
    SidebarComponent,
    FooterComponent,
    SettingsMenuComponent,
    DateMenuComponent,
    LayoutComponent,
    LayoutComponentAll,
    FreeMsgComponent,
    CheckListComponent,
    OopsNoDataComponent
  ],

})
export class SharedModule { }

