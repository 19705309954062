export interface RegisterUser {
  // firstname: string;
  // lastname: string;
  //  username: string;
  email: string;
  password: string;
}
export interface RegisterUserResponse {
  email: string;
  firstname: string;
  id: string;
  lastname: string;
  username: string;
}

export interface LoginGoogleUserResponse {
  accessToken: string;
  refreshToken: string;
  userId: number;
}
interface ProfileGoogle {
  email: string;
  firstname: string;
  lastname: string;
  name: string;
  photoUrl: string;
}
export interface UserIdentity {
  id: number;
  userId: number;
  externalId: string;
  provider: string;
  profileGoogle: ProfileGoogle;
  profile: ProfileGoogle;
  createdAt: string;
  updatedAt: string;
  roles: Array<string>;
  planName: string;
}

export interface AllPlan {
  id: string;
  name: string;
  details: object;
  price: {
    monthly: number;
    yearly: number;
  };
  link: {
    monthly: number;
    yearly: number;
  };
  featured: boolean;
}
export interface Plan {
  name: string;
  details: Details;
  featured: boolean;
}
export interface TypePlan {
  websites: number;
  locationSelectionAllowed: boolean;
  nbInternalPage: number;
  nbCompetitors: number;
  supportLevel: string;
  price: {
    monthly: number;
    yearly: number;
  };
  link: {
    monthly: number;
    yearly: number;
  };
}

export interface DetailItem {
  text: string;
  value: string;
  description: string;
  tooltip: string;
}
export interface Details {
  internalPagesOnly: boolean;
  nbWebsite: DetailItem;
  locationSelectionAllowed: DetailItem;
  devices: DetailItem;
  webVitals: DetailItem;
  nbInternalPage: DetailItem;
  internalPagesAudits: DetailItem;
  nbCompetitor: DetailItem;
  domainDataRetention: DetailItem;
  pageDataRetention: DetailItem;
  graphsExport: DetailItem;
  supportLevel: DetailItem;
  alerts: DetailItem;
  userSeats: DetailItem;
  rumMonthlyPV: any;
  realUserMonitoring: any;
}

export interface LoginUser {
  username: string;
  password: string;
}
export interface LoginResponse {
  ttl: string;
  userId: string;
  created: string;
  id: string;
  responseCode: string;
  superuserId: string;
  superuser: string;
  role: string;
  accessToken: string;
  refreshToken: string;
}
export interface RegisterGoogleUser {
  userId: number;
  provider: string;
  profile: {};
}
export class PageSpeedDataMobile {
  Title: string;
  URL: string;
  // IMG : string;
  internalURLs: string;
  strategy: string;
  superuserId: string;
  overall_category: string;
  psi_score: number;
  color_psi_score: string;
  CLS_0: number;
  CLS_1: number;
  CLS_2: number;
  Color_CLS: string;
  FCP_0: number;
  FCP_1: number;
  FCP_2: number;
  Color_FCP: string;
  FID_0: number;
  FID_1: number;
  FID_2: number;
  Color_FID: string;
  LCP_0: number;
  LCP_1: number;
  LCP_2: number;
  Color_LCP: string;
  INP_0: number;
  INP_1: number;
  INP_2: number;
  Color_INP: string;
}
export class PageSpeedDataDesktop {
  Title: string;
  URL: string;
  // IMG : string;
  internalURLs: string;
  strategy: string;
  superuserId: string;
  overall_category: string;
  psi_score: number;
  color_psi_score: string;
  CLS_0: number;
  CLS_1: number;
  CLS_2: number;
  Color_CLS: string;
  FCP_0: number;
  FCP_1: number;
  FCP_2: number;
  Color_FCP: string;
  FID_0: number;
  FID_1: number;
  FID_2: number;
  Color_FID: string;
  LCP_0: number;
  LCP_1: number;
  LCP_2: number;
  Color_LCP: string;
  INP_0: number;
  INP_1: number;
  INP_2: number;
  Color_INP: string;
}
export class CruxDataDesktop {
  Title: string;
  URL: string;
  // IMG : string;
  internalURLs: string;
  strategy: string;
  server: string;
  superuserId: string;
  psi_score: number;
  color_psi_score: string;
  CLS_0: number;
  CLS_1: number;
  CLS_2: number;
  Color_CLS: string;
  FCP_0: number;
  FCP_1: number;
  FCP_2: number;
  Color_FCP: string;
  FID_0: number;
  FID_1: number;
  FID_2: number;
  Color_FID: string;
  LCP_0: number;
  LCP_1: number;
  LCP_2: number;
  Color_LCP: string;
  INP_0: number;
  INP_1: number;
  INP_2: number;
  Color_INP: string;
}
export class cruxDataMobile {
  Title: string;
  URL: string;
  // IMG : string;
  internalURLs: string;
  strategy: string;
  server: string;
  superuserId: string;
  color_psi_score: string;
  CLS_0: number;
  CLS_1: number;
  CLS_2: number;
  psi_score: number;
  Color_CLS: string;
  FCP_0: number;
  FCP_1: number;
  FCP_2: number;
  Color_FCP: string;
  FID_0: number;
  FID_1: number;
  FID_2: number;
  Color_FID: string;
  LCP_0: number;
  LCP_1: number;
  LCP_2: number;
  Color_LCP: string;
  INP_0: number;
  INP_1: number;
  INP_2: number;
  Color_INP: string;
}
export class CruxDataApi {
  url: string;
  deviceDesktop: string;
  devicePhone: string;
  // location: US
  superuserId: string;
}

interface formgroup {
  title: string;
  url: string;
}
export class VisitsChartData {
  data: number[];
  registration: string;
  signOut: string;
  rate: string;
  all: string;
}
export class ProjectTimeData {
  name: string;
  users: string;
  percent: number;
  registrations: string;
  bounce: string;
  views: string;
  series: [
    {
      name: string;
      data: number[];
    }
  ];
}
export class WebsiteAnalyze {
  id?: string;
  title: string;
  originDomain: string;
  internalURLs: Array<formgroup> = [];
  competitors: Array<formgroup> = [];
  deviceMobile: boolean;
  deviceDesktop: boolean;
  country: string;
  userId: string;
  editable?: boolean;
  free?: boolean;
  sharedWith?: Array<string>;
  isFPDEnabled?: boolean;
  rumMonitoringLocations?: string[];
  sharedWithCount?: number;
}
export interface TypedataInternalPage {
  p75: Array<number>;
  diff: number;
  prop: prop;
}
export interface DataLcpFidClsTtfb {
  title: string;
  yyyymm: string;
  device: string;
  pctGoodLcp: string;
  pctAvgLcp: string;
  pctSlowLcp: string;
  p75Lcp: string;
  country: string;
  type: string;
}
export interface prop {
  good: Array<number>;
  needImprovement: Array<number>;
  poor: Array<number>;
}
export class InternalPageDetail {
  title: string;
  url: string;
  device: string;
  img: string;
  date: Array<Date> = [];
  fcp: TypedataInternalPage;
  cls: TypedataInternalPage;
  lcp: TypedataInternalPage;
  fid: TypedataInternalPage;
  inp: TypedataInternalPage;
  ttfb?: TypedataInternalPage;
  uxr?: number;
  arrayUxr: Array<any>;
  arrayTransferSize: Array<any>;
  deviceShare: any;
}
export interface modelBarUrl {
  id: string;
  originDomain: string;
  country: string;
  title: string;
}
export class BarUrl {
  id: string;
  originDomain: string;
  country: string;
  title: string;
}

export interface navTypeData {
  yyyymm: string;
  device: string;
  country: string;
  url: string;
  nav_navigate: number;
  nav_navigate_cache: number;
  nav_reload: number;
  nav_restore: number;
  nav_back_forward: number;
  nav_back_forward_cache: number;
  nav_prerender: number;
}
