<div class="layout-container">
  <app-header (isShowSidebar)="sidenav.toggle($event)" [isMenuOpened]="isShowSidebar" class="sticky-top"></app-header>
  @if(trialMessage && isTrialMessageVisible){
    <div class="d-flex justify-content-center align-items-center">
      <div class="d-flex justify-content-center align-items-center alert alert-danger d-flex align-items-center px-2 py-1 mt-3 rounded-2 w-auto" style="font-size: 0.85rem; background-color: #f8f8f8; border-color: #ffffff;">
        <div class="my-2 w-100 d-flex justify-content-center align-items-center">
          <mat-icon class="text-danger mt-1 me-2" style="font-size: 1rem;">info</mat-icon>
          <span class="text-danger">{{ trialMessage }}</span>
          <button class="btn btn-sm btn-primary ms-2 " (click)="navigateToSubscription()"
                  style="font-size: 0.75rem;">
            Subscribe
          </button>
          <span class="ms-2" (click)="closeTrialMessage()" style="cursor: pointer;">
            <mat-icon class="text-dark d-flex justify-content-center align-items-center" style="font-size: 0.90rem;">close</mat-icon>
          </span>
        </div>
      </div>
    </div>
    }
  <mat-sidenav-container class="layout" >
    <mat-sidenav #sidenav [(opened)]="isShowSidebar" class="layout-sidebar" [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"   [fixedTopGap]="60">
      <app-sidebar></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-feedback-widget></app-feedback-widget>
  <app-footer class="footer"></app-footer>
</div>
