<div class="d-flex flex-column justify-content-center align-items-center my-3" >
  <div class="my-3">
    <img
    src="./../../../../assets/page-not-found-computer-network-404-error-warning-vector-24919571.jpg"
    alt="logo"
    width="300"
    height="200"
    class="header__logo"
    />
  </div>
  <p class="fs-5 fw-medium" [innerHTML]="message"></p>
</div>
