// check-list.component.ts
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ValidationService, SiteData } from '../../services/validation.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface Step {
  label: SafeHtml;
  done: boolean;
}

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent implements OnInit, OnChanges {
  @Input() noSitesCreated: boolean;
  @Input() hasCreatedWebsite: boolean;
  @Input() hasSharedWebsite: boolean;
  @Input() areWeReceivingData: boolean;
  @Input() isCRUX: boolean;
  @Input() hasRUM: boolean;
  @Input() checklistId: string; // Identifiant unique
  allRequiredDone: boolean;

  steps: Step[] = [];

  constructor(
    private validationService: ValidationService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.validationService.initializeChecklist(this.checklistId, this.getCurrentSiteData());
    this.initializeSteps();
    this.checkValidation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initializeSteps();
    this.checkValidation();
  }

  get mainTitle(): string {
    return this.noSitesCreated
      ? 'Getting Started'
      : 'To go further, you can also do…';
  }

  private initializeSteps(): void {
    const list: Step[] = [];

    if (!this.isCRUX) {
      list.push({
        label: this.sanitizer.bypassSecurityTrustHtml(`
          Upgrade for RUM monitoring (required)<br />
          <a class="alert-prompt-link" href="/buyPlan">Upgrade now!</a>
        `),
        done: this.hasRUM,
      });
    }

    list.push({
      label: this.sanitizer.bypassSecurityTrustHtml(`
        Add a website (required)<br />
        <a href="/home/pageLoad/new" style="text-align: center;">
          Click here to add your first website
        </a>
      `),
      done: this.hasCreatedWebsite,
    });

    if (!this.isCRUX) {
      list.push({
        label: this.sanitizer.bypassSecurityTrustHtml(`Collect RUM data`),
        done: this.hasRUM && this.areWeReceivingData,
      });
    }

    list.push({
      label: this.sanitizer.bypassSecurityTrustHtml(`Share the website with your team (optional)`),
      done: this.hasSharedWebsite,
    });

    this.steps = list;
  }

  get totalSteps(): number {
    return this.steps.length;
  }

  get completedSteps(): number {
    return this.steps.filter(step => step.done).length;
  }

  get progressPercentage(): number {
    if (this.totalSteps === 0) return 0;
    return (this.completedSteps / this.totalSteps) * 100;
  }

  private getCurrentSiteData(): SiteData {
    return {
      noSitesCreated: this.noSitesCreated,
      hasCreatedWebsite: this.hasCreatedWebsite,
      hasSharedWebsite: this.hasSharedWebsite,
      isCRUX: this.isCRUX,
      areWeReceivingData: this.areWeReceivingData,
      hasRUM: this.hasRUM,
    };
  }

  private checkValidation(): void {
    const requiredSteps = this.steps.filter(step =>
      step.label.toString().includes('(required)') ||
      (!this.isCRUX && step.label.toString().includes('Collect RUM data'))
    );

    this.allRequiredDone = requiredSteps.every(step => step.done);
    if (this.allRequiredDone) {
      const siteData = this.getCurrentSiteData();
      this.validationService.setSiteData(this.checklistId, siteData);
      this.validationService.validate(this.checklistId);
    }
  }
}
