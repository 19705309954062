// validation.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface SiteData {
  noSitesCreated: boolean;
  hasCreatedWebsite: boolean;
  hasSharedWebsite: boolean;
  isCRUX: boolean;
  areWeReceivingData: boolean;
  hasRUM: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  private validations: { [key: string]: BehaviorSubject<boolean> } = {};
  private siteData: { [key: string]: BehaviorSubject<SiteData> } = {};

  constructor() {
    // Initialiser toutes les checklists nécessaires
    this.initializeAllChecklists();
  }

  private initializeAllChecklists() {
    const checklistIds = ['listwebsite', 'listwebsite-first-party-data'];
    checklistIds.forEach(id => {
      const storedValidation = localStorage.getItem(`validation_${id}`);
      const storedSiteData = localStorage.getItem(`siteData_${id}`);
      if (storedValidation && storedSiteData) {
        this.validations[id] = new BehaviorSubject<boolean>(JSON.parse(storedValidation));
        this.siteData[id] = new BehaviorSubject<SiteData>(JSON.parse(storedSiteData));
      } else {
        // Initialiser avec des valeurs par défaut
        this.initializeChecklist(id, {
          noSitesCreated: true,
          hasCreatedWebsite: false,
          hasSharedWebsite: false,
          isCRUX: false,
          areWeReceivingData: false,
          hasRUM: false,
        });
      }
    });
  }

  initializeChecklist(id: string, data: SiteData) {
    if (!this.validations[id]) {
      this.validations[id] = new BehaviorSubject<boolean>(false);
      this.siteData[id] = new BehaviorSubject<SiteData>(data);
      this.saveValidationState(id, false);
      this.saveSiteData(id, data);
    }
  }

  isValidated$(id: string) {
    if (!this.validations[id]) {
      this.initializeChecklist(id, {
        noSitesCreated: true,
        hasCreatedWebsite: false,
        hasSharedWebsite: false,
        isCRUX: false,
        areWeReceivingData: false,
        hasRUM: false,
      });
    }
    return this.validations[id].asObservable();
  }

  siteData$(id: string) {
    if (!this.siteData[id]) {
      this.initializeChecklist(id, {
        noSitesCreated: true,
        hasCreatedWebsite: false,
        hasSharedWebsite: false,
        isCRUX: false,
        areWeReceivingData: false,
        hasRUM: false,
      });
    }
    return this.siteData[id].asObservable();
  }

  validate(id: string) {
    if (this.validations[id]) {
      this.validations[id].next(true);
      this.saveValidationState(id, true);
    } else {
      this.initializeChecklist(id, {
        noSitesCreated: true,
        hasCreatedWebsite: false,
        hasSharedWebsite: false,
        isCRUX: false,
        areWeReceivingData: false,
        hasRUM: false,
      });
      this.validations[id].next(true);
      this.saveValidationState(id, true);
    }
  }

  resetValidation(id: string) {
    if (this.validations[id]) {
      this.validations[id].next(false);
      this.saveValidationState(id, false);
    }
  }

  setSiteData(id: string, data: SiteData) {
    if (this.siteData[id]) {
      this.siteData[id].next(data);
      this.saveSiteData(id, data);
    } else {
      this.initializeChecklist(id, data);
    }
  }

  private saveValidationState(id: string, state: boolean) {
    localStorage.setItem(`validation_${id}`, JSON.stringify(state));
  }

  private saveSiteData(id: string, data: SiteData) {
    localStorage.setItem(`siteData_${id}`, JSON.stringify(data));
  }
}
